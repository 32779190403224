<template>
  <div>
    <div class="comment-body" v-show="!editMode">
      <div class="comment-content" v-html="content"/>
      <div class="comment-actions">

        <v-btn icon @click="editMode = true" v-if="editable  && contactName">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="deleteComment" v-if="deletable  && contactName">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="edit-mode" v-show="editMode">
      <div class="editor">
        <quill-input v-model="mutableComment"/>
      </div>
      <div class="actions">
        <v-btn icon @click.prevent="saveComment">
          <v-icon>mdi-send</v-icon>
        </v-btn>
        <v-btn icon @click.prevent="editMode = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { UpdateCommentProxy } from '@/proxies/UpdateCommentProxy'
import { DeleteCommentProxy } from '@/proxies/DeleteCommentProxy'
import QuillInput from '@/components/QuillInput'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CommentBody',
  components: { QuillInput },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    contactName: {
      type: [Number,String],
      default: null
    },
    deletable: {
      type: Boolean,
      default: false
    },
    content: {},
    commentId: {
      required: true
    }
  },
  data () {
    return {
      editMode: false,
      mutableComment: this.content,
    }
  },
   computed: {
    ...mapGetters('account', ['currentUser']),
  },
  methods: {
    saveComment () {
      new UpdateCommentProxy()
        .create({
          item: {
            commentId: this.commentId,
            body: this.mutableComment
          }
        })
        .then(() => {
          this.editMode = false
          this.$emit('updated')
        })
    },
    deleteComment () {
      this.$confirm('Do you really want to delete?')
        .then(res => {
          if (res) {
            new DeleteCommentProxy()
              .create({
                item: {
                  commentId: this.commentId,
                }
              })
              .then(() => {
                this.$emit('updated')
              })
          }
        })
    },
  },
  watch: {
    content (val) {
      this.mutableComment = val
    }
  }
}
</script>

<style lang="scss">
.edit-mode {
  display: flex;
  align-content: center;

  .actions {
    align-items: center;
    display: flex;
  }
}

.comment-body {
  display: flex;
  align-items: center;

  .comment-content {
    flex: 1;
  }
}

</style>
