<template>
  <quill-editor
    class="editor"
    :value="value"
    @input="$emit('input', $event)"
    :options="editorOption"
  ></quill-editor>
</template>

<script>
const { mapGetters } = require('vuex')
import i18n from '@/plugins/i18n'

export default {
  name: 'QuillInput',
  props: {
    value: {},
    placeholder: {
      default: i18n.t('Write a comment...')
    },
    theme: {
      default: 'bubble'
    }
  },
  data () {
    return {
      editorOption: {
        theme: this.theme,
        placeholder: this.placeholder,
        modules: {
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@'],
            source: this.fetchUser,
            dataAttributes: ['id', 'value', 'denotationChar', 'link', 'target', 'disabled'],
            renderItem (item, searchTerm) {
              return item.name
            },
            onSelect: this.onMentionSelect
          },
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['clean']
          ]
        }
      }
    }
  },
  methods: {
    fetchUser (searchTerm, renderList, mentionChar) {
      if (searchTerm.length === 0) {
        renderList(this.getUsers, searchTerm)
      } else {
        const matches = []
        for (let i = 0; i < this.getUsers.length; i++) {
          if (
            ~this.getUsers[i].name.toLowerCase().indexOf(searchTerm.toLowerCase())
          ) {
            matches.push(this.getUsers[i])
          }
        }
        renderList(matches, searchTerm)
      }
    },
    onMentionSelect (item, insertItem) {
      let user = this.getUsers.find(u => u.id === item.id)
      insertItem({
        ...item,
        id: user.id,
        value: user.name
      }, true)
    }
  },
  computed: {
    ...mapGetters('common', ['getUsers'])
  },
}
</script>

<style lang="scss">
.editor {
  background-color: #fff;
  flex: 1;
  border-radius: 4px;
  border: 1px solid #e5e7eb
}
</style>
