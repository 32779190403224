import { BaseProxy } from "@/proxies/BaseProxy";

export class UpdateCommentProxy extends BaseProxy {
  constructor({ parameters = {} } = {}) {
    super({
      endpoint: `updateComment`,
      parameters,
    });
  }
}
