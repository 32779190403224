import { BaseProxy } from "@/proxies/BaseProxy";

export class DeleteCommentProxy extends BaseProxy {
  constructor({ parameters = {} } = {}) {
    super({
      endpoint: `deleteComment`,
      parameters,
    });
  }
}
