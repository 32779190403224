<template>
  <section class="projects container-fluid align-center px-6 pt-6 pb-0">
    <v-row :gutters="3" pa-2>
            <div class="ticket-overlay" @click="isVisible=!isVisible" v-show="isVisible"></div>
     
      <v-col class="sidebar-container">
        <div class="sidebar"  :class="isVisible ? 'visible':''" >
          <h3>{{ $t("Tasks") }}</h3>
          <v-menu
            ref="menu"
            v-model="datepicker_view"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateFormattedMomentjs" 
                :label="$t('Select date range')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="filters.dates = []"
                class="font-12"

              ></v-text-field>
            </template>
            <v-date-picker v-model="filters.dates" no-title scrollable range>
            </v-date-picker>
          </v-menu>

          <h4>{{ $t("Status") }}</h4>
          <v-list>
            <v-list-item-group
              v-model="filters.taskStatus"
              @change="getSections()"
            >
              <template v-for="(item, i) in status">
                <v-list-item
                  :key="`item-${i}`"
                  :value="item.value"
                  active-class="primary--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary accent-4"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>

          <h4>{{ $t("Projects") }}</h4>
          <div>
            <v-treeview
              v-model="filters.sectionId"
              selectable
              item-disabled="locked"
              :items="sectionGrouped"
            >
              <template v-slot:append="{ item, open }">
                <div class="total-badge" v-if="item.count > 0">
                  {{ item.count }}
                </div>
              </template>
            </v-treeview>
          </div>
          <!-- <v-list v-if="getProjects[0]">
            <v-list-item-group v-model="filters.projectId" multiple>
              <template v-for="(item, i) in getProjects">
                <v-list-item
                  :key="`item-${i}`"
                  :value="item.record_id"
                  active-class="primary--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.project_name"
                      ></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary accent-4"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list> -->

          <h4>{{ $t("Priority") }}</h4>
          <v-list>
            <v-list-item-group v-model="filters.priority">
              <template v-for="(item, i) in priority">
                <v-list-item
                  :key="`item-${i}`"
                  :value="item.record_id"
                  active-class="primary--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary accent-4"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
          <!-- <h4>Collection</h4> -->
        </div>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
                 <v-btn
              color="primary"
               @click="isVisible=!isVisible"
              depressed
              large 
              class="btn-mobile"
            > FILTER 
            </v-btn>
            <v-select
              :items="groupBy"
              v-model="selectedGroupBy"
              :label="$t('Group By')"
              item-text="name"
              item-value="value"
              flat
              dense
              hide-details
              style="width: 100px"
            ></v-select>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchKeyword"
              flat
              dense
              append-icon="mdi-magnify"
              :label="$t('Search')"
              single-line
              hide-details
              clearable
            ></v-text-field>
                   <v-btn
              color="success"
                v-if="userProfile == 'MANAGER'"
             :href="url"
              depressed
              large
              style="margin-top: -14px; min-width: 44px;margin-left: 10px;padding-left:5px;padding-right:5px;     height: 40px;"
            > <i class="fal fa-lg fa-file-excel"></i>
            </v-btn>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="tasks"
            class="elevation-1 task-table"
            :loading="loading"
            :items-per-page.sync="filters.rowsPerPage"
            :server-items-length="total"
            :page.sync="filters.page"
            :sort-by.sync="filters.sort.name"
            :sort-desc.sync="filters.sort.desc"
            :group-by="selectedGroupBy"
            :show-group-by="showGroupBy"
            :footer-props="{
              'items-per-page-options': [25, 50, 100],
            }"
          >
            <template v-slot:item.record_id="{ item }">
              <router-link
                :to="{ name: 'taskDetail', params: { id: item.record_id } }"
              >
                {{ item.record_id }}
              </router-link>
            </template>
            <template v-slot:item.subject="{ item }">
              <router-link
                :to="{ name: 'taskDetail', params: { id: item.record_id } }"
              >
                {{ item.subject }}
              </router-link>
            </template>
            <template v-slot:item.Owner="{ item }">
              <span :title="item.Owner"> {{ item.Owner | nameShorten }}</span>
            </template>
            <template v-slot:item.priority="{ item }">
              <span
                :title="item.cfd_priority_label"
                :style="{ color: '#' + item.cfd_priority_color }"
              >
                <i :class="'fa ' + item.cfd_priority_value"></i
              ></span>
            </template>
            <template v-slot:item.Status="{ item }">
              <v-chip :color="'#' + item.cfd_task_status_color + '85'" dark>
                {{ item.Status }}
              </v-chip>
            </template>
            <template #item.comments="{ item }">
              <v-btn icon color="accent" @click="openComments(item.record_id)">
                <v-icon>mdi-comment</v-icon>
              </v-btn>
              {{ item.customer_comments_count }}
            </template>
            <template v-slot:item.end_date="{ item }">
              {{ setTime(item.end_date) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-navigation-drawer
      width="600px"
      v-model="commentId"
      fixed
      temporary
      right
      app
    >
      <section class="drawer-comments">
        <div class="comments">
          <div
            class="comment shadow"
            v-for="comment in comments"
            :key="comment.record_id"
          >
            <div class="avatar">
              <v-avatar
                color="primary"
                size="36"
                v-if="comment.rel_contact_name_label"
                >{{ comment.rel_contact_name_label | firstChars }}
              </v-avatar>
              <v-avatar
                color="success"
                size="36"
                v-else-if="comment.rel_record_owner_label"
                >{{ comment.rel_record_owner_label | firstChars }}
              </v-avatar>
            </div>
            <div class="comment-info">
              <div class="name">
                {{
                  comment.rel_contact_name_label ||
                  comment.rel_record_owner_label
                }}
              </div>
              <div class="message">
                <comment-body
                  :editable="!!comment.editable"
                  :deletable="!!comment.editable"
                  :content="comment.body"
                  :comment-id="comment.record_id"
                  :contact-name="comment.rel_contact_name_label"
                  @updated="getComments"
                />
              </div>
              <div class="date">
                {{ comment.created_on | date }}
              </div>
            </div>
          </div>
          <div v-if="!comments.length" class="no-comment">No Comments</div>
        </div>
        <form class="reply" @submit.prevent="submitComment">
          <v-avatar color="primary" size="36"> SS </v-avatar>
          <quill-input v-model="comment" />
          <v-btn color="primary" type="submit">Send</v-btn>
        </form>
      </section>
    </v-navigation-drawer>
  </section>
</template>

<script>
import { TasksProxy } from "@/proxies/TasksProxy";
import { SectionsProxy } from "@/proxies/SectionsProxy";
import moment from "moment";
import { mapGetters,mapState  } from "vuex";
import { CommentsProxy } from "@/proxies/CommentsProxy";
import { CreateCommentProxy } from "@/proxies/CreateCommentProxy";
import CommentBody from "@/components/CommentBody";
import QuillInput from "@/components/QuillInput";

export default {
  name: "Task",
  components: { QuillInput, CommentBody },
  data() {
    return {
        url:'',
      filename:'',
      isVisible:false,
      headers: [
        {
          text: "#",
          align: "end",
          value: "record_id",
          width: 75,
          groupable: false,
        },
        {
          text: this.$t("Subject"),
          align: "start",
          value: "subject",
          groupable: false,
        },
        {
          text: "",
          value: "priority",
          sortable: false,
          width: 50,
          groupable: false,
        },
        {
          text: this.$t("Status"),
          value: "Status",
          width: 125,
          groupable: false,
        },
        {
          text: this.$t("Due Date"),
          value: "end_date",
          sortable: false,
          width: 80,
          groupable: false,
        },

        {
          text: "",
          value: "comments",
          groupable: false,
          width: 80,
        },
        {
          text: this.$t("Owner"),
          value: "Owner",
          width: 140,
          groupable: false,
        },
      ],
      filters: {
        page: 1,
        rowsPerPage: 50,
        sort: {
          name: ["record_id"],
          desc: [false],
        },
        projectId: [],
        status: [],
        priority: {},
        sectionId: [],
        taskStatus: [1, 2, 9, 10],
        keyword: "",
        dates: [],
      },
      datepicker_view: false,
      searchKeyword: "",
      keywordTimer: null,
      lastKeywordTime: 0,
      loading: false,
      tasks: [],
      total: 0,
      sectionGrouped: [],
      projects: [],
      sections: [],
      selectedGroupBy: "Date",
      groupBy: [
        { value: null, name: this.$t("None") },
        { value: "Date", field: "end_date", name: this.$t("Due Date") },
        {
          value: "Status",
          field: "cfd_task_status_label",
          name: this.$t("Status"),
        },
        {
          value: "Priority",
          field: "cfd_priority_label",
          name: this.$t("Priority"),
        },
        {
          value: "Owner",
          field: "rel_record_owner_label",
          name: this.$t("Record Owner"),
        },
      ],
      priority: [
        {
          name: this.$t("Urgent"),
          value: 1,
          icon: "mdi-inbox",
        },
        {
          name: this.$t("High"),
          value: 2,
          icon: "mdi-domain",
        },
        {
          name: this.$t("Medium"),
          value: 3,
          icon: "mdi-timer",
        },
        {
          name: this.$t("Low"),
          value: 4,
          icon: "mdi-check",
        },
      ],
      status: [
        {
          name: this.$t("Open"),
          value: [1, 2, 9, 10],
        },
        {
          name: this.$t("Backlog"),
          value: 6,
          show: false,
        },
        {
          name: this.$t("On Hold"),
          value: 5,
        },
        {
          name: this.$t("Completed"),
          value: 3,
        },
        {
          name: this.$t("Overdue"),
          value: 100,
        },
      ],
      commentId: null,
      showGroupBy: false,
      comment: "",

      comments: [],
    };
  },
  created() {
    this.getSections();
  },
  methods: {
    setTime(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getTasks() {
      this.loading = true;
      let paramaters = { ...this.filters };

      new TasksProxy()
        .setParameters(paramaters)
        .all()
        .then((response) => {
          let tasks = response.data;

          this.tasks = tasks.map((k) => {
            let res = { ...k };

            res["Status"] = res.cfd_task_status_label;
            res["Priority"] = res.cfd_priority_label;
            res["Owner"] = res.rel_record_owner_label;

            let dateDetail = moment(res.end_date).format("DD MM YYYY");
            res["Date"] = dateDetail;

            return res;
          });

          this.loading = false;
          this.total = response.totalCount;
        });


 let pm = {...paramaters};
      pm.forExport = 1
        new TasksProxy()
        .setParameters(pm)
        .all()
        .then((response) => {
          this.loading = false 
          this.url = response.url;
          this.filename = response.filename;
        })
    },
    getSections() {
      new SectionsProxy()
        .setParameters({
          taskStatus: this.filters.taskStatus ? this.filters.taskStatus : null,
          filterByStatus: 1,
          sort: {
            name: ["sort_order"],
            desc: [false],
          },
        })
        .all()
        .then((response) => {
          this.sections = [...response.data]; 
          this.groupSections();
        });
    },
    groupSections() {
      let res = [];

      this.sections.forEach((k) => {
        let found = res.find((a) => a.project == k.project);
        if (!found) {
          res.push({
            ...k,
            locked: false,
            children: [],
            id: "P" + k.project,
            name: k.rel_project_label,
          });
        }
      });

      this.sections.forEach((k) => {
        let found = res.find((a) => a.id == "P" + k.project);

        found.children.push({
          ...k,
          locked: false,
          children: [],
          id: k.record_id,
          count: k.tasks_count,
          name: k.section_name,
        });
      });
      this.sectionGrouped = res;
      return res;
    },
    getColor(calories) {
      if (calories > 3) {
        return "red";
      } else if (calories > 2) {
        return "orange";
      } else {
        return "green";
      }
    },
    openComments(id) {
      this.commentId = id;
    },
    getComments() {
      new CommentsProxy()
        .setParameters({
          taskId: this.commentId,
        })
        .all()
        .then((response) => {
          this.comments = response.data;
        });
    },
    submitComment() {
      new CreateCommentProxy()
        .create({
          item: {
            taskId: this.commentId,
            body: this.comment,
          },
        })
        .then(() => {
          this.comment = "";
          this.getComments();
        });
    },
  },
  computed: {
    ...mapGetters("common", ["getProjects"]),
   
    ...mapGetters('account', ['isLogin']),
    ...mapGetters('account', ['userProfile']),
    ...mapState("account", ["token"]), 
     computedDateFormattedMomentjs () {
        return this.filters.dates ? moment(this.filters.dates[0]).format('DD/MM/YY')+" ~ "+moment(this.filters.dates[1]).format('DD/MM/YY') :  []
      },
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler: "getTasks",
    },
    searchKeyword(val) {
      
      if (val && val.length > 2) { 
        var nowMS = new Date();
        if (!this.lastKeywordTime) {
          this.lastKeywordTime = new Date();
        }
        let diff = nowMS.getTime() - this.lastKeywordTime.getTime();

        if (diff < 500 && this.keywordTimer) {
          clearTimeout(this.keywordTimer);
        }
        this.keywordTimer = setTimeout(() => {
          this.filters.keyword = val;
        }, 350);

        this.lastKeywordTime = nowMS;
      } else if (val == "") {
        this.filters.keyword = "";
      }
    },
    commentId(val) {
      if (val) {
        this.getComments();
      } else {
        this.comments = [];
      }
    },
    selectedGroupBy(val) {
      if (val == "none") {
        this.showGroupBy = false;
      } else {
        this.showGroupBy = true;
      }
    },
  },
};
</script>

<style lang="scss">
.sidebar {
  background: #ebeef2;
  max-width: 500px;
  height: calc(100vh - 110px);
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;
  position: fixed;
  width: 270px;

  h3 {
    color: #465669;
    font-size: 18px;
    font-weight: 600;
    line-height: 3;
  }

  h4 {
    color: #465669;
    font-size: 14px;
    font-weight: 600;

    line-height: 2;
  }

  .v-list.theme--light {
    background: none;
  }

  .v-list {
    // max-height: 250px;
    // overflow: auto;
  }

  .v-list-item {
    flex-direction: row-reverse;
    height: 30px;
    min-height: 30px;
    padding: 0px;
  }

  .v-list-item__action {
    margin-left: 0 !important;
    margin: 2px 16px 2px 0px;
  }

  .v-list-item__title {
    align-self: center;
    font-size: 0.8rem;
    color: #465669;
  }
}

.v-text-field--outlined fieldset {
  background: #fff !important;
}

.task-table .v-data-table__wrapper {
  min-height: calc(100vh - 255px);
}

.drawer-comments {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #f6f6f6;

  .comments {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .no-comment {
      align-items: center;
      justify-content: center;
      display: flex;
      flex: 1;
      color: #bbb;
      font-size: 1.25rem;
      letter-spacing: 0.5rem;
    }

    .comment {
      display: flex;
      margin-bottom: 1rem;
      background-color: #fff;
      padding: 1rem;
      border-radius: 4px;

      .comment-info {
        flex: 1;
        margin-left: 1rem;

        .name {
          font-size: 13px;
          color: #006dcc;
        }

        .message {
          font-size: 13px;
          line-height: 20px;
          color: #465669;
        }

        .date {
          font-size: 12px;
          line-height: 18px;
          color: #465669;
        }
      }
    }
  }

  .reply {
    display: flex;
    align-items: center;
    flex: none;

    > :not([hidden]) ~ :not([hidden]) {
      margin-left: 1rem;
    }
  }
}

.v-treeview-node__root {
  display: flex;
  align-items: center;
  min-height: 35px;
  padding-left: 3px;
  padding-right: 3px;
  position: relative;
  font-size: 12px;
  margin-left: -10px;
}

.v-application--is-ltr .v-treeview-node__checkbox {
  margin-left: 0px;
}
.v-treeview-node__level {
  width: 18px;
}
.total-badge {
  background: #f6f6f6;
  border-radius: 50%;
  padding: 3px 0px;
  text-align: center;
  height: 20px;
  font-size: 9px;
  width: 20px;
}
.font-12 input{
  font-size: 13px;
}

.v-application .success {
    background-color: #1D6F42 !important;
    font-size: 16px;
}
</style>
