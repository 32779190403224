import { BaseProxy } from "@/proxies/BaseProxy";

export class CommentsProxy extends BaseProxy {
  constructor({ parameters = {} } = {}) {
    super({
      endpoint: `comments`,
      parameters,
    });
  }
}
