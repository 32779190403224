var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"projects container-fluid align-center px-6 pt-6 pb-0"},[_c('v-row',{attrs:{"gutters":3,"pa-2":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}],staticClass:"ticket-overlay",on:{"click":function($event){_vm.isVisible=!_vm.isVisible}}}),_c('v-col',{staticClass:"sidebar-container"},[_c('div',{staticClass:"sidebar",class:_vm.isVisible ? 'visible':''},[_c('h3',[_vm._v(_vm._s(_vm.$t("Tasks")))]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"font-12",attrs:{"value":_vm.computedDateFormattedMomentjs,"label":_vm.$t('Select date range'),"prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.filters.dates = []}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepicker_view),callback:function ($$v) {_vm.datepicker_view=$$v},expression:"datepicker_view"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.filters.dates),callback:function ($$v) {_vm.$set(_vm.filters, "dates", $$v)},expression:"filters.dates"}})],1),_c('h4',[_vm._v(_vm._s(_vm.$t("Status")))]),_c('v-list',[_c('v-list-item-group',{on:{"change":function($event){return _vm.getSections()}},model:{value:(_vm.filters.taskStatus),callback:function ($$v) {_vm.$set(_vm.filters, "taskStatus", $$v)},expression:"filters.taskStatus"}},[_vm._l((_vm.status),function(item,i){return [_c('v-list-item',{key:("item-" + i),attrs:{"value":item.value,"active-class":"primary--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary accent-4"}})],1)]}}],null,true)})]})],2)],1),_c('h4',[_vm._v(_vm._s(_vm.$t("Projects")))]),_c('div',[_c('v-treeview',{attrs:{"selectable":"","item-disabled":"locked","items":_vm.sectionGrouped},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.count > 0)?_c('div',{staticClass:"total-badge"},[_vm._v(" "+_vm._s(item.count)+" ")]):_vm._e()]}}]),model:{value:(_vm.filters.sectionId),callback:function ($$v) {_vm.$set(_vm.filters, "sectionId", $$v)},expression:"filters.sectionId"}})],1),_c('h4',[_vm._v(_vm._s(_vm.$t("Priority")))]),_c('v-list',[_c('v-list-item-group',{model:{value:(_vm.filters.priority),callback:function ($$v) {_vm.$set(_vm.filters, "priority", $$v)},expression:"filters.priority"}},[_vm._l((_vm.priority),function(item,i){return [_c('v-list-item',{key:("item-" + i),attrs:{"value":item.record_id,"active-class":"primary--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary accent-4"}})],1)]}}],null,true)})]})],2)],1)],1)]),_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-btn',{staticClass:"btn-mobile",attrs:{"color":"primary","depressed":"","large":""},on:{"click":function($event){_vm.isVisible=!_vm.isVisible}}},[_vm._v(" FILTER ")]),_c('v-select',{staticStyle:{"width":"100px"},attrs:{"items":_vm.groupBy,"label":_vm.$t('Group By'),"item-text":"name","item-value":"value","flat":"","dense":"","hide-details":""},model:{value:(_vm.selectedGroupBy),callback:function ($$v) {_vm.selectedGroupBy=$$v},expression:"selectedGroupBy"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"flat":"","dense":"","append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchKeyword),callback:function ($$v) {_vm.searchKeyword=$$v},expression:"searchKeyword"}}),(_vm.userProfile == 'MANAGER')?_c('v-btn',{staticStyle:{"margin-top":"-14px","min-width":"44px","margin-left":"10px","padding-left":"5px","padding-right":"5px","height":"40px"},attrs:{"color":"success","href":_vm.url,"depressed":"","large":""}},[_c('i',{staticClass:"fal fa-lg fa-file-excel"})]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1 task-table",attrs:{"headers":_vm.headers,"items":_vm.tasks,"loading":_vm.loading,"items-per-page":_vm.filters.rowsPerPage,"server-items-length":_vm.total,"page":_vm.filters.page,"sort-by":_vm.filters.sort.name,"sort-desc":_vm.filters.sort.desc,"group-by":_vm.selectedGroupBy,"show-group-by":_vm.showGroupBy,"footer-props":{
            'items-per-page-options': [25, 50, 100],
          }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.filters, "rowsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.filters, "rowsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)},"update:sortBy":function($event){return _vm.$set(_vm.filters.sort, "name", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filters.sort, "name", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filters.sort, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filters.sort, "desc", $event)}},scopedSlots:_vm._u([{key:"item.record_id",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'taskDetail', params: { id: item.record_id } }}},[_vm._v(" "+_vm._s(item.record_id)+" ")])]}},{key:"item.subject",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'taskDetail', params: { id: item.record_id } }}},[_vm._v(" "+_vm._s(item.subject)+" ")])]}},{key:"item.Owner",fn:function(ref){
          var item = ref.item;
return [_c('span',{attrs:{"title":item.Owner}},[_vm._v(" "+_vm._s(_vm._f("nameShorten")(item.Owner)))])]}},{key:"item.priority",fn:function(ref){
          var item = ref.item;
return [_c('span',{style:({ color: '#' + item.cfd_priority_color }),attrs:{"title":item.cfd_priority_label}},[_c('i',{class:'fa ' + item.cfd_priority_value})])]}},{key:"item.Status",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":'#' + item.cfd_task_status_color + '85',"dark":""}},[_vm._v(" "+_vm._s(item.Status)+" ")])]}},{key:"item.comments",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"accent"},on:{"click":function($event){return _vm.openComments(item.record_id)}}},[_c('v-icon',[_vm._v("mdi-comment")])],1),_vm._v(" "+_vm._s(item.customer_comments_count)+" ")]}},{key:"item.end_date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setTime(item.end_date))+" ")]}}])})],1)],1)],1),_c('v-navigation-drawer',{attrs:{"width":"600px","fixed":"","temporary":"","right":"","app":""},model:{value:(_vm.commentId),callback:function ($$v) {_vm.commentId=$$v},expression:"commentId"}},[_c('section',{staticClass:"drawer-comments"},[_c('div',{staticClass:"comments"},[_vm._l((_vm.comments),function(comment){return _c('div',{key:comment.record_id,staticClass:"comment shadow"},[_c('div',{staticClass:"avatar"},[(comment.rel_contact_name_label)?_c('v-avatar',{attrs:{"color":"primary","size":"36"}},[_vm._v(_vm._s(_vm._f("firstChars")(comment.rel_contact_name_label))+" ")]):(comment.rel_record_owner_label)?_c('v-avatar',{attrs:{"color":"success","size":"36"}},[_vm._v(_vm._s(_vm._f("firstChars")(comment.rel_record_owner_label))+" ")]):_vm._e()],1),_c('div',{staticClass:"comment-info"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(comment.rel_contact_name_label || comment.rel_record_owner_label)+" ")]),_c('div',{staticClass:"message"},[_c('comment-body',{attrs:{"editable":!!comment.editable,"deletable":!!comment.editable,"content":comment.body,"comment-id":comment.record_id,"contact-name":comment.rel_contact_name_label},on:{"updated":_vm.getComments}})],1),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm._f("date")(comment.created_on))+" ")])])])}),(!_vm.comments.length)?_c('div',{staticClass:"no-comment"},[_vm._v("No Comments")]):_vm._e()],2),_c('form',{staticClass:"reply",on:{"submit":function($event){$event.preventDefault();return _vm.submitComment($event)}}},[_c('v-avatar',{attrs:{"color":"primary","size":"36"}},[_vm._v(" SS ")]),_c('quill-input',{model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v("Send")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }