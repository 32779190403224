import { BaseProxy } from "@/proxies/BaseProxy";

export class CreateCommentProxy extends BaseProxy {
  constructor({ parameters = {} } = {}) {
    super({
      endpoint: `createComment`,
      parameters,
    });
  }
}
